import "../App.css";
import { TiImg, TeImg, SeImg, SiImg, NeImg, FiImg, FeImg } from "./Img";
export function History() {
  return (
    <div>
      <h1>نبذة</h1>
      <br />
      <p dir="rtl" lang="ar">
        بدأ كبار العلماء و الفلاسفة في عام ١٩٢٠ بإستكشاف ما يدعي بالأنماط
        الشخصية. <br />
        في ذلك الوقت قام العالم السويسري "كارل يونج" بكتابة كتابه الشهير "the
        psycological types" الذي وضح فيه بوصف مفصل عن ما أصبح اليوم واحداً من
        أكثر التصنيفات المستخدمة حول العالم.
      </p>
      <br />
      <p lang="ar" dir="rtl">
        لاحظ يونج اختلاف جوهري بين الناس من حيث كونهم أكثر إنفتاحاً
        (Extraverted) أو علي تواصل أكبر بالعالم الخارجي و ما حولهم، و كونهم أكثر
        انطوائية (Introverted) أو علي تواصل أكبر بعالمهم الداخلي حيث أفكارهم و
        مشاعرهم و ذكرياتهم. بعدها لاحظ اختلافات أخري من حيث ما يفعله الناس في كل
        من هذين العالمين، هذه الاختلافات هي ما تدعي "The Cognitive Functions" أو
        "الوظائف المعرفية".
      </p>
    </div>
  );
}

export function WhatIsCognitiveFunctions() {
  return (
    <div>
      <p lang="ar">
        صنف يونج الوظائف المعرفية إلي نوعين أساسيين، أولهما الإدراك (perception)
        و الثاني إصدار الأحكام (Judgment). يندرج تحت الإدراك، الإدراك الواقعي
        (Sensation)، و الإدراك الحدسي (Intuition). في حين كان التفكير (thinking)
        و الشعور (Feeling) مصدري الأحكام. كما ذكر أن أي نشاط عقلي يقوم به
        الإنسان مبني علي واحدة علي الأقل من العمليات المعرفية الأربعة الذين تم
        ذكرهم. بعد ذلك قام بتقسيمهم من حيث استخدامهم داخليا أو خارجيا. مستخدماً
        الإستعارات قام يونج بتسمية ال٨ وظائف بوضع أول حرف من اسم كل منها يتبعها
        اول حرف من ماهيتها سواء كانت داخلية او خارجية علي سبيل المثال : لو أردنا
        التعبير عن الشعور (Feeling) داخلياً (Introverted) فتكون Fi، و هكذا ليصبح
        لدينا (Fe, Fi, Te, Ti, Se, Si, Ne, Ni)
      </p>
    </div>
  );
}

export function WhatIsMbti() {
  return (
    <div>
      <h1>ما هي أنماط مايرز و بريجز ؟</h1>
      <p>
        عام ١٩٤٠، بدأت إيزابيل مايرز بإنشاء اختبار شخصي لمساعدة الناس علي تحديد
        مكانهم طبقا لنظرية يونج. لكي يصبح ذلك الاختبار في يومنا هذا واحداً من
        أكثر اختبارات تحليل الشخصية استخداماً، كما أدي إلي مفهوم شبه عالمي علي
        أن البشر مقسمين لستة عشر شخصية و يتم الرمز إلي كل واحدة منها بأربعة حروف
        مثل ISTJ أو ENFP.
      </p>
      <br />
    </div>
  );
}

export function AboutTest() {
  return (
    <div>
      <h1>وجب التنويه</h1>
      <p>
        لا يُنصح في وقتنا هذا استخدام مؤشر مايرز لتحديد الشخصية، فغالباً تكون
        النتائج غير دقيقة نظراً لوضع الشخص النفسي أو عدم فهمه للسؤال، إلخ...
        فالطريقة المثلي لتحديد نمط شخصيتك طبقاً لنظرية يونج هي القراءة و الفهم
        المتعمق للنظرية.
      </p>
    </div>
  );
}

export function OverView() {
  return (
    <div>
      <p>
        كل الوظائف المعرفية التي تبناها يونج تشغل دوراً في عقولنا لكن بنسب
        مختلفة بين كل وظيفة و أخري و بين كل شخص و آخر، و لكي يصبح التعبير عن تلك
        النسب واضحاً تم تصنيف استخدامنا للوظائف الثمانية بترتيب معين يدل علي تلك
        النسب، كما أن اختلاف أماكن الوظائف في ذلك الترتيب يدل بدوره علي أحد
        أنماط مايرز الستة عشر.
      </p>
    </div>
  );
}

export function HeroPar() {
  return (
    <div>
      كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
      الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
      البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
      الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
      ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في
      الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد
      تكمن السعاده فيما نتحمله من كد وأسي.
    </div>
  );
}

export function ParentPar() {
  return (
    <div>
      كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
      الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
      البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
      الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
      ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في
      الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد
      تكمن السعاده فيما نتحمله من كد وأسي.
    </div>
  );
}
export function ChildPar() {
  return (
    <div>
      كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
      الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
      البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
      الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
      ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في
      الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد
      تكمن السعاده فيما نتحمله من كد وأسي.
    </div>
  );
}
export function InferiorPar() {
  return (
    <div>
      كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
      الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
      البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
      الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
      ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في
      الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد
      تكمن السعاده فيما نتحمله من كد وأسي.
    </div>
  );
}

export function OpposingPar() {
  return (
    <div>
      كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
      الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
      البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
      الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
      ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في
      الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد
      تكمن السعاده فيما نتحمله من كد وأسي.
    </div>
  );
}

export function CriticalPar() {
  return (
    <div>
      كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
      الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
      البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
      الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
      ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في
      الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد
      تكمن السعاده فيما نتحمله من كد وأسي.
    </div>
  );
}

export function TricksterPar() {
  return (
    <div>
      كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
      الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
      البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
      الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
      ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في
      الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد
      تكمن السعاده فيما نتحمله من كد وأسي.
    </div>
  );
}

export function DemonPar() {
  return (
    <div>
      كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
      الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
      البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
      الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
      ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في
      الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد
      تكمن السعاده فيما نتحمله من كد وأسي.
    </div>
  );
}

export function Ti() {
  return (
    <div>
      <img src={TiImg} alt=""/>
      <p lang="ar">
        بعتذر عن فترة الانقطاع عن الجروب ال Ti أو التفكير الداخلي 1st Hero :
        INTP, ISTP 2nd Parent: ENTP, ESTP 3rd Child: INFJ, ISFJ 4th infrr: ESFJ,
        ENFJ 5th oppsing role: ENTJ, ESTJ 6th Critical: INTJ, ISTJ 7th
        Trickster: ENFP, ESFP 8th Demon: INFP, ISFP =========== تعريف عام النوع
        : وظيفة حكمية التوجه : داخلي ذاتي الطبيعة : تسلسلية، بنائية الأساس
        البنائي : التعريف و الصياغة =========== ال Ti تعرف على أنها وظيفة المنطق
        عند البشر، بس خلينا متفقين الأول أن المنطق مش حكر على تايب بعينه، زي ال
        Se مثلا، مينفعش نقول ان اللي عندهم Se ضعيفة هم أشخاص غير موجودين في
        الحياة وسطنا، لكن اللي بيميز مستخدم فانكشن معينة بشكل أساسي عن مستخدمها
        بشكل فطري هو الميل و الكفاءة، ال Ti هي وظيفة التعريف بناء الأنظمة، و
        الأنظمة هنا مش مقصود بيها حجات زي الدساتير الدولية مثلا، انما المقصود
        بيها هو قدرة الشخص على وضع كل المعلومات اللي قدامه داخل إطار تسلسلي واضح
        (بالنسباله)، و بيصنع زي دايرة مغلقة على ذاتها قل ما تجد فيها ثغرات حتى
        لو منطق ال Ti مش متناسب مع أهدافك و رؤيتك الا انك مش هتلاقي غالبا اي
        مشكلة بنائية في كلام ال Ti user، مش لازم تقتنع لكنك مش هتلاقي ثغرات، و
        هو دا اساس عمل ال Ti، هي عبارة عن كشاف حقايق، بتحط المعلومة من اليمين
        تخرجلك من الشمال عليها تصريح بكونها صح و لا غلط، متسألش ازاي، هو بيقدر
        يعمل كدا و دي شغلانته، عشان كدا ال Ti user يقدر يعرف بكل سهولة ايه
        المشكلة في نظام ما أيا كانت مرجعيته بمجرد ما يكون عنده داتا كفاية عن
        المرجعية دي يعني كذبة أن ال Ti مش بيستعين بمعلومات خارجية ما هي إلا
        مبالغة و مزايدة على الحقيقة، لأن زي ما ذكرنا في ال Te و قولنا انها
        استقرائية بتاخد معلومة و تستنتج الباقي، فا ال Ti كمان لا غنى عن
        المعلومات في شغلها لكنها بتستخدم المعلومة كعنصر يتم قياسه بذاته وسط الكل
        بمعنى أسهل عشان تفرق بينهم، ال Te بتقدر تعرف المعلومات كلها من معلومة
        واحدة لكن مش بالضرورة يكون الاستقراء دا صحيح.. و ال Ti محتاجة تعرف كل
        المعلومات لكنها في النهاية هتوصلك للمشاكل و الثغرات و الحقايق من وسط كل
        الداتا اللي عندها ============ ازاي الفانكشن دي بتشتغل؟ بتشتغل عن طريق
        التعريف ثم الفحص ثم التهيئة و الإعمال من هنا نقدر نقسم شغل ال Ti ل ٤
        أقسام ١- التجريد و التعريف ٢-الفحص و التدقيق ٣- تشكيل الروابط ٤- صناعة
        النظام و عمليا تقدر تضيف ٤ أقسام تاني، و دول هم نفس الأقسام الأولى بس
        بالعكس، و الترتيب المعكوس دا بيظهر أثناء تفكيك أنظمة موجودة بالفعل مش
        أثناء صناعة نظام جديد في المرحلة الأولى بتبدأ ال Ti في محاولة فهم ما هي
        بصدد التعامل معه، اي شخص في العادي بياخد المعلومة و خلاص تمام كدا
        استوعبها، لكن ال Ti عندهم شوية بطئ في الاستيعاب، و دا نتيجة أن الفهم
        عندهم بيمر بمرحلتين، الأولى هي مرحلة التجريد، ال Ti هنا بياخد جزء من
        سلوكيات الحدس و يبدأ يحول المعلومات اللي عنده لتصورات مجردة، انت بتشوف
        طيارة مثلا لكن ال Ti user بيشوف خط مستقيم حواليه انحناءات، دي الهيئة
        العامة لعملية التجريد عند ال Ti، عملية تبسيط شامل للمعلومات و وضعها في
        صورتها ال basic، و دا يعتبر نوعا ما أقل كفاءة من عمليات تجريد الحدس لكنه
        بيؤدي الغرض المطلوب، المرحلة التانية في القسم الأول هي التعريف، بعد ما
        جردت المعلومة انت ك Ti user هتبدأ في محاولة وصف التجريد دا لنفسك، هتسأل
        نفسك ( هو ايه دا؟) في محاولة منك لاستيعاب المعلومة بأبسط أشكالها القسم
        التاني هو الفحص و التدقيق، بعد ما استوعبت أبعاد المعلومة، هتبدأ تسأل
        نفسك، هو دا صحيح؟.. يعني هل مثلا الطيارة دي فعلا خط مستقيم حواليه
        انحناءات زي ما انا شايف؟.. و دا سر قوة ال Ti، يمكن السؤال دا يكون صعب
        على أي فانكشن، لكن ال Ti user بيقدر بكل سهولة يطابق ما بين اللي هو شايفه
        من التعريف بتاعه و ما بين المعلومة اللي أخدها من مصدر خارجي،. الجدير
        بالذكر أن ال Ti user دائما و أبدا هيدي أفضلية لتعريفه الشخصي لو متطابقش
        مع المعلومة الخارجية،. و اللي احيانا بيكون سلوك خاطئ القسم التالت بتبدأ
        فيه ال Ti بتشكيل روابط بين كل المعلومات اللي تم التأكد منها بالفعل، و دي
        تعتبر مرحلة تهيئة أو تحضير لصناعة النظام المغلق اللي ذكرناه فوق، و دا
        يمكن السبب في كون ال Ti جبارة فيما يخص تطوير ما هو موجود بالفعل، دا بسبب
        ان كل مستخدم ti عنده طريقة مختلفة في صناعة الروابط بين المعلومات اللي في
        دماغه، كل واحد منهم هتلاقي عنده طريقة مختلفة في تركيب المعطيات،. و يمكن
        دي سمة مش حتى ف ال Te، لو جبت اتنين Te user و اديتهم نفس المعلومات غالبا
        هتلاقيهم طلعو بنفس الاستنتاج باتخلافات بسيطة،. لكن ال Ti users كل واحد
        فيهم هيطلع بشكل و تدفق مختلف و اللذيذ أن كل شكل من دول بيؤدي وظيفته
        القسم الرابع هو صناعة النظام، خلاص بقى عند المرحلة دي ال Ti بيكون عنده
        معلومات موثوقة و مُعرفة و جاهزة ليتم وضعها في نظام، و نظام ال Ti فيه شبه
        كبير من معادلات ال Ni اللي ذكرناها سابقا، الفرق ان ال Ni user مش بيكون
        عارف الطريقة اللي صنع بيها المعادلة دي، لكن ال Ti user بيكون شايف كل
        حاجة، مين بيودي على فين و هكذا لو سألت Ni user عن النتيجة لتغير مقدار
        احد المتغيرات في معادلته هيديلك الإجابة سريعا،. لكن ال Ti user مش هيسمح
        بتغير عشوائي في العوامل بتاعت نظامه، كل حاجة محسوبة و كل شئ يجب أن يكون
        في مكانه الصحيح بمقداره الصحيح، مينفعش تقوله بشكل عشوائي هيحصل ايه لو
        عملنا كذا.. لأنه هو شخصيا شايف ان النتيجة. اللي هتطلع من التغيير دا غبية
        و مش شغالة وفقا للسيستم اللي هو حطه ============== ايه شكلها عند كل
        تايب؟ 1.Ti as hero function ال Ti hero شخص مفكر، مستقل بآراءه و عنده
        رؤية شخصية في كل شئ، ماشي يفرز ف المعلومات و يصحح الأخطاء، بيحب يكون كل
        شئ في سياق سليم و واضح،. عيبه الوحيد انه مش بيتقبل منطق الغير لو تعارض
        مع المنطق بتاعه، حتى إذا كان منطق الغير دا مفهوش ثغرات بردو، معاك مثال
        خناقة أينشتاين و الكوانتم ب اعتباره very developed Ti 2. Ti as parent
        function ال parent هي الصورة الأنقى لل فانكشن و الأعلى كفاءة، ال Ti
        parent يمكن ميكونش بنفس تزمت ال Ti hero فيما يخص التدقيق في كل المعطيات،
        لكنه لما بيبدأ يمارس العملية دي بيطلع نتايج ممتازة، و جدير بالذكر أنه مش
        متزمت لرأيه زي ال Ti hero كونه عنده Te critical بتساعده على تقبل مصادر
        معلومات خارجية في حال كانت موثوقة، لكنه عموما شخص ذكي و لماح و كشاف
        معلومات من الطراز الرفيع 3. Ti as child function دا صاحبك الحنيوك خصوصا
        لو كان INFJ، ال child فانكشن حاملة لل method لكنها بتستقبل المعلومات
        بشكل خاطئ، ورغم أن ال Si و ال Ni عند ال IFJ بيدارو كتير من قصور ال Ti
        child، الا انه لا يجب إنكار أن المنطق عندهم كويس و منظم، على عكس باقي
        وظائف ال child اللي فعلا بتكون سخيفة، الا ان ال Ti child هي أسرع فانكشن
        بتنضج من بين كل ال childs عشان المنطق في حد ذاته بينمو بالممارسة و صدقني
        مفيش حد بيمارس فانكشن قد حامل ال child فيها 4. Ti as infrr function ال
        Ti infrr هو الخوف من عدم القدرة على فهم التسلسل المنطقي للأنظمة، أو
        الخوف من عدم القدرة على فهم و تعريف الأشياء، ال EFJs بردو بيدارو القصور
        دا بال Si و ال Ni، لكنهم في خوف دائم من كونهم مش مدركين تماما هل هم
        عندهم تعريف واضح و فهم عميق للمعلومات و لا لا 5. Ti as oppsing role
        function ال ETJs عندهم قلب دائم من فكرة هل هم صح ولا لا، بس للأسف بما
        انهم مفتقرين للعملية بتاعت ال Ti بشكل مؤثر بيلجؤا لتعزيز ثقتهم في
        المعلومات عن طريق اكتر من مصدر، دا سلوك جيد عموما،. لكن زي ما ذكرنا ف
        بوست ال Te الإفراط في السلوك دا بيخلق تهديد محو الهوية 6. Ti as critical
        function ال critical هي فانكشن إعادة النظر، المنظور الرابع ليك بعد
        الهيرو و البارنت و الشايلد، ميخفاش عنكم كونها مزعجة في ظهورها و تفاعلنا
        معاها، لكن ال Ti critical عموما فانكشن بتخلي صاحبها دايما حابب يعيد
        تقييم الأوضاع بذاته، بتخليه مهتم أنه يكون عنده يقين شخصي من قراره حتى لو
        تم بناءه على معطيات خارجية و دا سهم مباشر مع ال fi child بالمناسبة 7. Ti
        as trickster function المنطق بالنسبالهم هو 1+1=2، تسأله طيب انت عرفت
        ازاي أن 1+1=2 هتلاقيه بيقولك عماد قالي، بعدين ما هم باينين قدامك اتنين
        أهم، دا مش معناه انه شخص مفتقر للمنطق.. مش هتروح تقوله انا نطيت من الدور
        العاشر و نزلت على رجلي و تستناه يصدقك، احنا بنقول انه مش مهتم يعرف ازاي
        المعلومة اللي عنده جات مقولناش انه عبيط، هو بالنسباله الأمور واضحة مش
        اكتر فا ليه اعيد تفكير تاني، و طبعا الهيرو عندهم سواء Se أو ne بتخليهم
        مش شايفين أهمية فعلا لإعادة التدقيق في الأمور 8.Ti as Demon function
        كدرع دفاعي ال Ti هتخلي الشخص يبدأ دايما في إسقاط عجزه عن تعريف المعطيات
        اللي عنده في صورة تشكيك للآخرين في معلوماتهم، هتلاقي ال IFP دايما بيقولك
        وانت عرفت ازاي أن دا حقيقي، غالبا أن غلط عشان لو صح كان زمانك نجحت و
        مغالطات احتكام للنتائج و ليلة كبيرة اوي عشان بس يداري نقطة أن هو شخصيا
        مش قادر يعمل تعريف أو تسلسل للحجات اللي حواليه
      </p>
    </div>
  );
}

export function Te() {
  return (
    <div>
      <img src={TeImg} alt=""/>
      <p>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
        الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
        البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
        الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
        ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب
        في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما
        قد تكمن السعاده فيما نتحمله من كد وأسي.
      </p>
    </div>
  );
}
export function Fi() {
  return (
    <div>
      <img src={FiImg} alt=""/>
      <p>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
        الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
        البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
        الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
        ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب
        في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما
        قد تكمن السعاده فيما نتحمله من كد وأسي.
      </p>
    </div>
  );
}
export function Fe() {
  return (
    <div>
      <img src={FeImg} alt=""/>
      <p>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
        الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
        البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
        الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
        ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب
        في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما
        قد تكمن السعاده فيما نتحمله من كد وأسي.
      </p>
    </div>
  );
}

export function Ni() {
  return (
    <div>
      <img  alt=""/>
      <p>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
        الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
        البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
        الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
        ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب
        في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما
        قد تكمن السعاده فيما نتحمله من كد وأسي.
      </p>
    </div>
  );
}

export function Ne() {
  return (
    <div>
      <img src={NeImg} alt=""/>
      <p>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
        الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
        البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
        الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
        ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب
        في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما
        قد تكمن السعاده فيما نتحمله من كد وأسي.
      </p>
    </div>
  );
}
export function Si() {
  return (
    <div>
      <img src={SiImg} alt=""/>
      <p>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
        الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
        البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
        الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
        ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب
        في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما
        قد تكمن السعاده فيما نتحمله من كد وأسي.
      </p>
    </div>
  );
}
export function Se() {
  return (
    <div className="fun">
      <img src={SeImg} alt=""/>
      <p>
        كن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار النشوة وتمجيد
        الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة
        البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء
        الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية
        ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب
        في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما
        قد تكمن السعاده فيما نتحمله من كد وأسي.
      </p>
    </div>
  );
}
