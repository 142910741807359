export const CFImg = (require("../images/Cognitive functions.png"))
export const MbtiImg = require("../images/mbti types.png")
export const HeroImg = require("../images/hero.jpeg") 
export const ParentImg = require("../images/parent.jpeg") 
export const ChildImg = require("../images/child.jpeg") 
export const InferiorImg = require("../images/inferior.jpeg") 
export const OpposingImg = require("../images/opposing.png")
export const CriticalImg = require("../images/critical.png")
export const TrickImg = require("../images/trickster.png")
export const DemonImg = require("../images/demon.png")
export const ArchetypesImg = require("../images/Archetypes.png")
export const TiImg = require("../images/Ti.jpg")
export const TeImg = require("../images/Te.jpg")
export const FiImg = require("../images/Fi.jpg")
export const FeImg = require("../images/Fe.jpg")
export const NeImg = require("../images/Ne.jpg")
export const SiImg = require("../images/Si.jpg")
export const SeImg = require("../images/Se.jpg")